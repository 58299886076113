.banner {
  transition: all $primary-transition-delay ease;
  background: $yellow;
  position: fixed;
  height: 65px;
  z-index: 99;
  @media(min-width: $screen-sm-min) {
    height: 85px;
  }

  .swedish-flag {
    display: none;
    @media(min-width: $screen-sm-min) {
      margin-right: 15px;
      margin-top: -11px;
      display: block;
      float: right;
    }
    img {
      margin: 0 auto;
      width: 100px;
      @media(min-width: $screen-sm-min) {
        float: right;
      }
    }
  }

  &.menu-opened {
    &.large-header {
      .small-logo {
        position: absolute;
        display: block;
        top: 0;
      }
    }
    .nav-container {
      button {
        .icon-bar {
          background: $blue;
        }
        span:nth-child(2) {
          transform: rotate(45deg) translateX(5px) translateY(5px);
        }
        span:nth-child(3) {
          opacity: 0;
        }
        span:last-child {
          transform: rotate(-45deg) translateX(8px) translateY(-7px);
        }
      }
      nav {
        opacity: 1;
        pointer-events: auto;
      }
    }
    .swedish-flag {
      background: $yellow;
      position: absolute;
      display: block;
      width: 100%;
      bottom: 0;
    }
  }
  
  .nav-container {
    display: block;

    @media(min-width: $screen-sm-min) {
      margin-top: 23px;
      padding-right: 20px;
    }

    .menu-toggle {
      button {
        background-color: transparent;
        border-radius: 0;
        margin-top: 13px;
        z-index: 999;
        border: 0;
      }
      .icon-bar {
        transition: background-color $primary-transition-delay,transform $primary-transition-delay,opacity $primary-transition-delay;
        border-radius: 0;
        background: $blue;
        width: 31px;
        height: 3px;
      }
      .icon-bar+.icon-bar {
        margin-top: 6px;
      }

      @media(min-width: $screen-sm-min) {
        display: none;
      }
    }

    nav {
      transition: opacity .3s ease-out;
      pointer-events: none;
      background: #fff;
      position: fixed;
      height: 100%;
      z-index: 99;
      width: 100%;
      opacity: 0;
      margin: 0;
      left: 0;
      top: 0;
      @media(min-width: $screen-sm-min) {
        background: transparent;
        pointer-events: auto;
        position: static;
        height: auto;
        opacity: 1;
      }
    }
  }

  &.large-header {
    background: transparent;
    height: auto;
    
    .large-logo {
      display: block;
    }

    .small-logo {
      display: none;
    }
    
    .nav-container {
      @media(min-width: $screen-sm-min) {
        margin-top: 47px;
      }
      a {
        @media(min-width: $screen-sm-min) {
          color: #fff;
          &:hover {
            opacity: $primary-hover-opacity;
          }
        }
      }
    }
    .menu-toggle {
      .icon-bar {
        background: #fff;
      }
    }
  }

  .brand {
    transition: opacity $primary-transition-delay;
    display: inline-block;
    margin-top: 16px;
    max-width: 145px;
    margin-left: 0;
    display: inline-block;
    @media(min-width: $screen-sm-min) {
      margin-top: 21px;
      margin-left: 20px;
      max-width: none;
    }
  }

  .large-logo {
    display: none;
  }

  .small-logo {
    position: relative;
    display: block;
    z-index: 999;
    width: 100px;
    @media(min-width: $screen-sm-min) {
      width: auto;
    }
    img {
      max-width: none;
      height: 35px;
      margin: 0;
      @media(min-width: $screen-sm-min) {
        height: auto;
      }
    }
  }

  .logo {
    background: #fcd21f;
    max-width: 180px;
  }

  nav {
    margin-bottom: 0;
    display: table;
    border: 0;

    ul {
      transform: translate(-50%,-50%);
      text-align: center;
      position: absolute;
      padding: 0 15px;
      float: none;
      width: 100%;
      margin: 0;
      left: 50%;
      top: 50%;
      @media(min-width: $screen-sm-min) {
        padding-right: 30px;
        position: static;
        transform: none;
        float: right;
        width: auto
      }
      .menu-item {
        margin-bottom: 15px;
        margin-left: 0;
        margin-top: 0;
        padding-left: 0;
        @media(min-width: $screen-sm-min) {
          margin-left: 30px;
          float: left;
          margin-bottom: 0;
        }
        &.active > a {
          text-decoration: underline;
          color: $blue;
        }
        a {
          font-family: 'sweden_sansregular', sans-serif;
          transition: all $primary-transition-delay;
          line-height: 38px;
          font-size: 35px;
          color: $blue;
          padding: 0;
          @media(min-width: $screen-sm-min) {
            font-size: 22px;
            &:hover {
              color: #fff;
            }
          }
          &:hover,
          &:focus {
            background: transparent;
          }
        }
      }
    }
  }
}

/* headroom styles */
.headroom {position: fixed;top: 0;left: 0;right: 0; z-index: 99; transition: all .2s ease-in-out;}
.headroom--unpinned {top: -106px; z-index: 999;}
.headroom--pinned {top: 0;}
.headroom--unpinned .brand { opacity: 0; }