@media print {
  .banner,
  .content-info,
  .toolbox-bar,
  .toolbox-bar span,
  .share-buttons,
  .related-title,
  .related-articles {
    display: none !important;
  }

  body {
    font-family: Georgia, 'Times New Roman', serif;
    max-width: 100%;
  }

  a {
    font-size: 12px;
    &:link {
      text-decoration: underline;
      font-weight: bold;
      color: #06c;
    } 
    &:link:after {
      content: " (" attr(href) ") ";
    }
  }

  .home {
    .icon-down,
    .play-video,
    a,
    .entry-summary p {
      display: none;
    }

    img {
      width: 100%;
    }

    article.col-md-6 {
      float: left;
      max-width: 50%;
    }

    article.col-md-12 {
      float: none;
    }
  }
}

/*
  .single {
    body,
    div,
    .main {
      width: 100%; 
      margin: 0; 
      float: none; 
    }
  }
}*/