.page {
  .content {
    margin-top: 100px;
    padding-bottom: 100px;
    @media(min-width: $screen-sm-min) {
      margin-top: 150px;
    }

    .page-header {
      margin-top: 0;
      padding: 0 30px;
      margin-bottom: 50px;
      @media(min-width: $screen-sm-min) {
        padding: 0;
      }
      h1 {
        text-align: center;
        margin-top: 0;
        margin-bottom: 40px;
        @media(min-width: $screen-sm-min) {
          font-size: 42px;
        }
      }
    }

    .excerpt {
      max-width: 680px;
      p {
        font-size: 16px;
        line-height: 25px;
        @media(min-width: $screen-sm-min) {
          font-size: 22px;
          line-height: 32px;
        }
      }

      a {
        text-decoration: underline;
      }
    }
  }

  p {
    max-width: $article-width;
    margin: 0 auto;
    margin-bottom: 24px;
  }

  &.toolbox {
    .content {
      @extend .mobile-padding;
      .entry-content {
        p {
          padding: 0;
        }
      }
    }

    .main {
      @extend .clearfix;
      @extend .center-block;
      max-width: $article-width;
      float: none;
    }
    .page-header {
      margin-bottom: 30px;
      padding: 0;
      @media(min-width: $screen-sm-min) {
        margin-bottom: 80px;
      }
    }
  
    article {
      margin-bottom: 80px;
      &:last-child {
        margin-bottom: 0;
      }
      h2 {
        text-align: left;
        @media(min-width: $screen-sm-min) {
          font-size: 26px; 
        }
      }

      .downloads {
        padding: 0;
        li {
          background: url(../images/download-icon.svg) 0 6px no-repeat;
          list-style: none;
          padding-left: 26px;
          font-size: 18px;
          a:hover {
            color: $blue;
          }
          p {
            font-size: inherit;
            color: #9b9b9b;
            line-height: 24px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.calendar {
    .content {
      @extend .mobile-padding;
      .container {
        div:first-child {
          margin-top: 10px;
        }
      }
      .month {
        p, span {
          font-family: 'sweden_sansregular', sans-serif;
          font-size: 32px;
          line-height: 39px;
          @media(min-width: $screen-sm-min) {
            font-size: 42px;
            line-height: 45px;
          }
        }

        p {
          color: $yellow;
          margin-bottom: 0;
        }

        span {

        }
      }

      .row {
        &.month {
          margin-bottom: 5px;
          @media(min-width: $screen-sm-min) {
            margin-top: 100px;
            margin-bottom: 70px;
          }
        }
        @media(min-width: $screen-sm-min) {
          display: flex; 
        }
      }
      article {
        @media(max-width: $screen-xs-min) {
          @extend .clearfix;
          float: none;
          padding: 0;
          padding-top: 26px;
        }
      
        &:first-child {
          border-top: 1px solid;
          @media(min-width: $screen-sm-min) {
            border-top: 0;
          }
        }
        @media(min-width: $screen-sm-min) {
          padding-left: 30px;
          padding-right: 0;
        }

        &:nth-child(2n+1) {
          @media(min-width: $screen-sm-min) {
            border-right: 1px solid #979797;
          }
        }
        aside {
          width: 20%;
          float: left;
          @media(min-width: $screen-sm-min) {
            width: 13%;
          }
          p {
            font-family: 'sweden_sansregular', sans-serif;
            text-align: center;
            font-size: 46px;
            line-height: 1;
            margin: 0;
            @media(min-width: $screen-sm-min) {
              font-size: 56px;
              line-height: 66px;
            }
          }
          span {
            font-family: 'zurich_condensed', sans-serif;
            text-transform: uppercase;
            font-size: 14px;
            @media(min-width: $screen-sm-min) {
              font-size: 16px;
            }
          }
        }
        & > div:first-of-type {
          width: 80%; 
          float: left;
          padding-left: 30px;
          h2 {
            margin-top: 8px;
            @media(max-width: $screen-xs-min) {
              margin-bottom: 20px;
            }
            @media(min-width: $screen-sm-min) {
              margin-top: 14px;
            }
          }
          p {
            padding: 0;
            font-size: 16px;
            line-height: 24px;
            @media(min-width: $screen-sm-min) {
              font-size: 18px;
            }
          }
          a {
            color: #9b9b9b;
          }
        }
      }
    }
  }

  &.companies {
    .content {
      @extend .mobile-padding;
    }

    .filter {
      margin-bottom: 15px;
      padding: 0;

      li {
        position: relative;
        display: inline-block;
        padding-left: 0;
        margin-bottom: 4px;

        a {
          font-family: 'sweden_sansregular', sans-serif;
          padding: 10px 20px;
          background: #eeeeee;
          display: block;
          &.active,
          &:hover {
            background: $yellow;
          }
        }
        span {
          font-family: 'zurich_condensed', sans-serif;
          position: absolute;
          font-size: 14px;
          right: 5px;
          top: 0;
        }
      }
    }

    .alphabetical-filter {
      border-top: 1px solid;
      padding: 13px 0 0 17px;
      margin: 0 0 64px 0;
      @media(min-width: $screen-sm-min) {
        text-align: center;
        padding-left: 0;
      }
      li {
        font-family: 'sweden_sansregular', sans-serif;
        list-style: none;
        float: left;
        width: 35px;
        padding-left: 0;
        padding-right: 20px;
        font-size: 20px;
        color: #ddd;
        @media(min-width: $screen-sm-min) {
          display: inline;
          width: auto;
          float: none;
        }

        &:last-child {
          padding-right: 0;
          a {
            padding-right: 20px;
          }
        }
        a {
          color: #aaa;
          &:hover {
            text-decoration: none;
            color: #000;
          }
        }
      }
    }
    
    .company-content {
      .row {
        display: flex;
        flex-wrap: wrap;
      }
      .letter {
        font-family: 'zurich_condensed', sans-serif;
        font-size: 42px;
        line-height: 1;
        padding: 0;
        @media(max-width: $screen-sm-min) {
          border-bottom: 1px solid;
        }
      }
    }
    article {
      margin-bottom: 40px;
      padding: 0;
      @media(min-width: $screen-sm-min) {
        margin-top: 25px;
        margin-bottom: 90px;
        padding-right: 30px;
        border-top: 1px solid;
      }

      h2 {
        margin-top: 15px;
        margin-bottom: 10px;
        a:hover {
          text-decoration: underline;
        }
      }

      ul.tags {
        margin: 0;
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          display: inline;
          color: #9b9b9b;
          font-size: 16px;
          font-family: 'zurich_condensed', sans-serif;
          a {
            font-size: 16px;
            color: #9b9b9b;
            text-transform: uppercase;
          }
        }
      }
    
      .entry-content {
        margin-top: 20px;
        margin-bottom: 13px;
        display: none;
        p {
          line-height: 24px;
          font-size: 18px;
          padding: 0;
        }
      }

      .accordion-btn {
        display: inline-block;
        margin-top: 5px;
        img {
          display: inline-block;
          transition: 0.2s ease-in-out;
          &.up {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &.stories {
    .main {
      
      .container {
        @extend .clearfix;
        @extend .center-block;
        @extend .mobile-padding;
        max-width: $article-width;
        float: none;
      }
    }

    .page-header {
      margin-bottom: 100px;
    }

    article {
      @media(min-width: $screen-sm-min) {
        margin-bottom: 35px;
      }

      img {
        @media(min-width: $screen-sm-min) {
          max-width: 172px;
          float: left;
          width: 25%;
        }
      }

      a {
        display: block;
      }

      h2 {
        margin-top: 5px;
        font-size: 22px;
        @media(min-width: $screen-sm-min) {
          margin: 0;
        }
      }

      div {
        margin-bottom: 50px;
        text-align: center;
        margin-top: 20px;
        text-align: center;

        @media(min-width: $screen-sm-min) {
          padding-left: 25px;
          text-align: left;
          height: 117px;
          float: left;
          width: 70%;
          margin: 0;
        }
        span {
          font-size: 13px;
          @media(min-width: $screen-sm-min) {
            margin-bottom: 10px;
            font-size: 16px;
          }
        }
      }
    }
  }

}