.grid-container {
  article {
    padding: 0;
    
    @media(min-width: $screen-sm-min) {
      padding: 20px 10px 0 10px;
    }
    .mobile-content {
      padding: 20px 30px;
      p {
        margin-bottom: 0;
      }
      hr {
        width: 55px;
        background: #979797;
        text-align: left;
        margin: 0;
        border: 0;
        height: 1px;
        margin-top: 10px;
      }
    }
    &.calendar {
      .calendar-link {
        font-family: 'zurich_condensed', sans-serif;
        text-transform: uppercase;
        position: absolute;
        top: 40px;
        left: 48px;
        font-size: 18px;
        color: #fff;
        &:before {
          background: url(../images/yellow-arrow.svg) 0px 0px;
          background-size: 12px 13px;
          position: relative;
          display: block;
          height: 13px;
          width: 12px;
          float: left;
          content: '';
          right: 7px;
          top: 5px;
        }
      }
      .entry-summary {
        span {
          font-family: 'sweden_sansregular', sans-serif;
          text-transform: none;
          color: $yellow;
          font-size: 28px;
        }
      }
    }

    .theme {
      left: 12%;
      right: 12%;
      top: 20px;
      @media(min-width: $screen-sm-min) {
        left: 15%;
        right: 15%;
        top: 42px;
      }
    }

    &.reason, &.fact {

      .theme-wrapper {
        position: relative;
        background-color: $grid-bg-color;
        .theme {
          position: absolute;
          color: #fff;
          z-index: 9;
        }
      }
      .image {
        .image-gradient {
          position: absolute;
          background: #000;
          opacity: 0.6;
          width: 100%;
          height: 100%;
          z-index: 2;
          top: 0;
        }
        .theme, .content-wrapper {
          z-index: 2;
        }
      }
    }

    .col-bg {
      background-color: $grid-bg-color;
      width: 100%;
      height: 0;
      padding-bottom: 68.06451613%;
      .image-gradient {
        background-image: none !important;
        &:before {
          background-image: none !important;
        }
      }
    }

    a:hover > .image-gradient {
      &:before {
        opacity: 1;
      }
      &.hover-disabled:before {
        opacity: 0;
      }
    }
  
    .gradient-wrapper {
      position: relative;

      .image-gradient {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        @include gradient-vertical(rgba(255, 255, 255, 0), #000, 41%, 100%);
        &:before {
          @include gradient-vertical(rgba(255, 255, 255, 0), #000, 11%, 100%);
          width: 100%;
          height: 100%;
          transition: opacity $primary-transition-delay;
          position: absolute;
          opacity: 0;
          content: '';
          top: 0;
          display: block;
        }
      }
      .entry-summary {
        position: absolute;
        bottom: 20px;
        left: 30px;
        right: 30px;
        transition: bottom $primary-transition-delay linear;
        &.hover {
          @media(min-width: $screen-sm-min) {
            //bottom: 50px;
          }
          p {
            max-height: 200px;
            opacity: 1;
          }
        }
        @media(min-width: $screen-sm-min) {
          bottom: 30px;
          left: 40px;
          right: 40px;
        }

        h2 {
          line-height: 26px;
          font-size: 21px;
          color: #fff;
          margin: 0;
          @media(min-width: $screen-sm-min) {
            margin-bottom: 10px;
            line-height: 52px;
            margin-top: 7px;
            font-size: 44px;
          }
          a {
            color: #fff;
          }
        }

        p {
          transition: all $primary-transition-delay linear;
          line-height: 30px;
          margin-bottom: 0;
          font-size: 15px;
          max-height: 1px;
          color: #fff;
          opacity: 0;
          @media(min-width: $screen-sm-min) {
            max-width: 80%;
            font-size: 20px;
          }
        }
      }
    }
  }
}

.single, .page {
  .post, .page {
    a {
      transition: opacity $primary-transition-delay;
      &:hover {
        opacity: $primary-hover-opacity;
      }
    }
  }
  .content {
    header {
      h1 {
        margin-top: 25px;
        margin-bottom: 50px;
      }
    }
  }
  .entry-content {
    h1, h2, h3, h4,
    ul, ol ,
    .entry-content-asset {
      max-width: $article-width;
      margin: 0 auto;
      iframe {
        @media(max-width: $screen-sm-min) {
        max-width: 100%;
        height: auto;
        }
      }
    }

    a {
      text-decoration: underline;
    }

    h1, h2, h3, h4 {
      margin-top: 35px;
      margin-bottom: 20px;
      @media(min-width: $screen-sm-min) {
        margin-top: 60px;
        margin-bottom: 30px;
      }
    }

    h1, h2, h3, h4,
    p {
      @media(max-width: $screen-sm-min) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    ul, ol {
      margin-top: 30px;
      margin-bottom: 36px;
      padding-left: 50px;
      padding-right: 30px;
      @media(min-width: $screen-sm-min) {
        margin-bottom: 50px;
        padding-left: 40px;
        padding-right: 0;
      }
      li {
        padding-left: 10px;
        @media(min-width: $screen-sm-min) {
          padding-left: 0;
        }
      }
    }
    
    img {
      margin-bottom: 50px;
    }

    .entry-content-asset {
      margin-bottom: 30px;
    }
  

    .wp-caption {
      margin: 0 auto;
      margin-bottom: 35px;
      @media(min-width: $screen-sm-min) {
        margin-bottom: 50px;
      }
      padding: 0;
      border: 0;
      img {
        margin-bottom: 12px;
        @media(min-width: $screen-sm-min) {
          margin-bottom: 18px;
        }
      }
      .wp-caption-text {
        padding: 0 30px;
        text-align: center;
        @media(min-width: $screen-sm-min) {
          padding: 0;
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }

  h1, h2, h3, h4 {
    @media(min-width: $screen-sm-min) {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }

  h2 {
    font-size: 20px;
    @media(min-width: $screen-sm-min) {
      font-size: 26px;
    }
  }

  .theme {
    margin-top: 10px;
    @media(min-width: $screen-sm-min) {
      margin-top: 50px;
    }
  }

  .excerpt {
    line-height: 36px;
    @media(min-width: $screen-sm-min) {
      font-size: 26px;
    }
  }
  
  img {
    @extend .img-responsive;
    @extend .center-block;
  }

  li {
    margin-bottom: 10px;
  }

  p,
  li {
    @media(min-width: $screen-sm-min) {
      font-size: 22px;
      line-height: 32px; 
    }
  }

  blockquote {
    @extend .center-block;
    font-family: 'sweden_sansregular';
    font-size: $large-font;
    margin-bottom: 70px;
    text-align: center;
    max-width: 882px;
    padding: 0;
    border: 0;
    &:before {
      content: '\201D';
      color: #fecb00;
      font-size: 170px;
      line-height: 1;
      clear: both;
      display: block;
      text-align: center;
      height: 80px;
      @media(min-width: $screen-sm-min) {
        font-size: 250px;
        height: 130px;
      }
    }
    &.highlight {
      padding-top: 30px;
      &:before {
        margin: 0 auto;
        margin-bottom: 40px;
        border-radius: 4px;
        border-top: 4px solid;
        border-bottom: 3px solid;
        content: '';
        width: 63px;
        height: 0;
      }

      p:first-child {
        &:after {
          content: '';
        }
      }
      
    }

    p {
      &:after {
        content: '';
      }
      @media(min-width: $screen-sm-min) {
        font-size: 20px;
      }
      &:first-child {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 25px;
        @media(min-width: $screen-sm-min) {
          font-size: $large-font;
          margin-bottom: 36px;
          line-height: 66px;
          max-width: 882px;
        }
        &:after {
          content: '\201D';
        }
      }
    }
  }
}

.single {
  .content {
    margin-top: 106px;

    .related-title {
      font-family: 'zurich_condensed';
      font-size: 16px;
      color: #000;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 15px;
      @media(min-width: $screen-sm-min) {
        font-size: 18px;
      }
    }

    header {
      max-width: 800px;
      margin: 0 auto;
      padding: 0 30px;
      @media(min-width: $screen-sm-min) {
        padding: 0;
      }
      
      h1 {
        margin-top: 18px;
        margin-bottom: 30px !important;
      }
    
      .excerpt {
        margin-bottom: 46px;
        font-family: 'sweden_sansregular';
        p {
          line-height: 26px;
          margin-bottom: 0;
          font-size: 20px;
          @media(min-width: $screen-sm-min) {
            font-size: 26px;
            line-height: 36px;
          }
        }
      }
    }
  }

  .entry-content {
    margin-bottom: 55px;
    @media(min-width: $screen-sm-min) {
      margin-bottom: 70px;
    }

    p {
      max-width: $article-width;
      margin: 0 auto;
      margin-bottom: 36px;
      @media(min-width: $screen-sm-min) {
        margin-bottom: 45px;
      }
    }
  }

  .material {
    max-width: $article-width;
    margin: 0 auto;
    padding: 0 30px;
    margin-bottom: 100px;
    @media(min-width: $screen-sm-min) {
      padding: 0;
    }
    h3 {
      margin-bottom: 20px;
      font-size: 18px;
      margin-top: 0;
      @media(min-width: $screen-sm-min) {
        font-size: 20px;
      }
    }
    ul {

      padding-left: 0;

      li {
        margin-bottom: 16px;
        padding-left: 30px;
        list-style: none;
        @media(min-width: $screen-sm-min) {
          margin-bottom: 10px;
        }

        &.link {
          background: url('../images/link-icon.svg') 3px 2px no-repeat;
          @media(min-width: $screen-sm-min) {
            background-position: 3px 10px;
          }
        }
        &.file {
          background: url('../images/file-icon.svg') 3px 2px no-repeat;
          @media(min-width: $screen-sm-min) {
            background-position: 3px 7px;
          }
        }

        span {
          line-height: 20px;
          font-size: 15px;
          display: block;
          color: #9b9b9b;
        }
      }
    }
  }
}

.related-articles {
  background: #f1f1f1;
  padding: 70px 0;
  @media(min-width: $screen-sm-min) {
    padding: 90px 0;
  }
  .container {
    @media (min-width: 1312px) {
      width: 1290px;
    }
    
    @media(min-width: $screen-sm-min) {
      display: table;
    }

    .row {
      @media(min-width: $screen-sm-min) {
        display: table-row;
      }
    }

    article {
      padding-bottom: 50px;
      max-width: 75%;
      margin: 0 auto;
      &:last-child {
        padding-bottom: 0;
      }

      @media(min-width: $screen-sm-min) {
        max-width: none;
        padding: 0 15px;
        margin: 0;
        display: table-cell;
        float: none;
      }

      .hover-wrapper {
        position: relative;
      }

      .image-hover {
        display: none;
        transition: opacity $primary-transition-delay;
        position: absolute;
        background: #000;
        opacity: 0;
        height: 100%;
        width: 100%;

        @media(min-width: $screen-sm-min) {
          display: block;
        }
      }    

      span {
        font-family: 'zurich_condensed', 'sans-serif';
        text-transform: uppercase;
        margin-bottom: 10px;
        display: block;
        font-size: 16px;
        color: #6a6a6a;
      }

      .image-wrapper {
        display: block;
        @media(max-width: $screen-sm-min) {
          height: auto !important;
        }
        @media(min-width: $screen-sm-min) {
          vertical-align: bottom;
          display: table-cell;
        }
        a {
          position: relative;
        }

        img {
          max-height: 250px;
          width: auto;
          @media(min-width: $screen-sm-min) {
            max-height: none;
            width: 100%;
          }
        }
      }
      header {
        margin-bottom: 10px;
        text-align: center;
        margin-top: 20px;
        padding: 0;
        @media(min-width: $screen-sm-min) {
          text-align: left;
          margin-top: 30px;
        }
        span {
          margin-bottom: 5px;
          font-size: 13px;
          color: #000;
          @media(min-width: $screen-sm-min) {
            font-size: 16px;
            color: #6a6a6a;
          }
        }
        h2 {
          text-align: center;
          line-height: 26px;
          font-size: 22px;
          margin: 0;
          @media(min-width: $screen-sm-min) {
            text-align: left;
          }
        }
      }
      .entry-content {
        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}