@import "components/_owl-carousel";
@import "common/_variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower
@import url("//hello.myfonts.net/count/2f7d90");

@font-face {
    font-family: 'sweden_sansregular';
    src: url('../fonts/swedensans-webfont.eot');
    src: url('../fonts/swedensans-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/swedensans-webfont.woff2') format('woff2'),
         url('../fonts/swedensans-webfont.woff') format('woff'),
         url('../fonts/swedensans-webfont.ttf') format('truetype'),
         url('../fonts/swedensans-webfont.svg#sweden_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sweden_sansbold';
    src: url('../fonts/swedensansbold-webfont.eot');
    src: url('../fonts/swedensansbold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/swedensansbold-webfont.woff2') format('woff2'),
         url('../fonts/swedensansbold-webfont.woff') format('woff'),
         url('../fonts/swedensansbold-webfont.ttf') format('truetype'),
         url('../fonts/swedensansbold-webfont.svg#sweden_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {font-family: 'zurich';src: url('../fonts/2F7D90_0_0.eot');src: url('../fonts/2F7D90_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2F7D90_0_0.woff2') format('woff2'),url('../fonts/2F7D90_0_0.woff') format('woff'),url('../fonts/2F7D90_0_0.ttf') format('truetype');}
 
@font-face {font-family: 'zurich_condensed';src: url('../fonts/2F7D90_1_0.eot');src: url('../fonts/2F7D90_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2F7D90_1_0.woff2') format('woff2'),url('../fonts/2F7D90_1_0.woff') format('woff'),url('../fonts/2F7D90_1_0.ttf') format('truetype');}
 
@font-face {font-family: 'zurich_bold_condensed';src: url('../fonts/2F7D90_2_0.eot');src: url('../fonts/2F7D90_2_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2F7D90_2_0.woff2') format('woff2'),url('../fonts/2F7D90_2_0.woff') format('woff'),url('../fonts/2F7D90_2_0.ttf') format('truetype');}

@import "common/_global";
@import "common/_print";
@import "components/_buttons";
@import "components/_comments";
@import "components/_forms";
@import "components/_grid";
@import "components/_wp-classes";
@import "components/_slides";
@import "components/_share";
@import "layouts/_header";
@import "layouts/_sidebar";
@import "layouts/_footer";
@import "layouts/_pages";
@import "layouts/_posts";

body {
  font-family: 'zurich', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: antialiased;
  text-rendering: optimizeLegibility;
  color: #000;
}

h1,h2,h3,h4 {
  font-family: 'sweden_sansregular', 'sans-serif';
}

h1 {
  font-size: 28px;
  @media(min-width: $screen-sm-min) {
    font-size: $large-font;
  }
}

h2 {
  font-size: 25px;
   @media(min-width: $screen-sm-min) {
    font-size: 44px;
  }
}

h3 {
  font-size: 20px;
  @media(min-width: $screen-sm-min) {
    font-size: 26px;
  }
}

p {
  margin-bottom: 45px;
}

p, li {
  font-size: 16px;
  line-height: 25px;
  @media(min-width: $screen-sm-min) {
    font-size: 22px;
    line-height: 32px
  }
}

ul {
  padding-left: 40px;
}

li {
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 25px;
  @media(min-width: $screen-sm-min) {
    line-height: 32px;
    font-size: 22px;
  }
}

a,
a:focus, a:hover {
  text-decoration: none;
  color: #000;
}

.main {
  padding: 0;
  margin: 0;
}

.error404 {
  background: $grey;
  .content {
    text-align: center;
    height: 100vh;
    .content-wrapper {
      transform: translateY(-60%);
      position: relative;
      top: 40%;
    }
    h1, a {
      color: $yellow;
    }
  }
}

.home {

  .hero {
    position: relative;

    .overlay {
      position: relative;
      z-index: 9;
      opacity: 1;

      .copyright {
        transform: rotate(-90deg) translate(100%);
        transform-origin: 100% 100%;
        position: absolute;
        font-size: 9px;
        opacity: $primary-hover-opacity;
        bottom: 10px;
        color: #fff;
        right: 0;
        @media(min-width: $screen-sm-min) {
          font-size: 12px;
        }
      }

      .hero-image {
        background-position: center center;
        background-size: cover;
        height: 460px;
        width: 100%;
        @media(min-width: $screen-sm-min) {
          height: 720px;
        }
      }

      .play-video {
        img {
          width: 100px;
          transition: opacity $primary-transition-delay;
          @media(min-width: $screen-sm-min) {
            width: 156px;
          }
        }

        &:hover img {
          opacity: $primary-hover-opacity;
        }
      }

      .icon-down {
        background: url(../images/white-arrow.svg) no-repeat;
        transform: translate(-50%,-50%) translateZ(0) scale(1);
        transition: bottom 0.3s;
        position: absolute;
        height: 30px;
        bottom: 15px;
        width: 30px;
        left: 50%;
        &:hover {
          bottom: 7px;
        }
      }
    }

    .image-gradient {
      width: 100%;
      height: 100%;
      position: absolute;
      background: #000;
      opacity: 0.45;
    }
    #ytplayer {
      position: absolute;
      display: none;
      border: 0;
      top: 0;
    }
    .title {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 54%;
      transform: translateY(-54%);
      p {
        font-family: 'sweden_sansregular', sans-serif;
        line-height: 40px;
        margin-bottom: 0;
        font-size: 32px;
        color: #fff;
        @media(min-width: $screen-sm-min) {
          font-size: 74px;
          line-height: 88px;
          margin-bottom: 45px;
        }
      }

      .play-video {
        position: relative;
        top: 15px;
        @media(min-width: $screen-sm-min) {
          position: static;
          top: 0;
        }
      }
    }
  }

  .hero-excerpt {
    padding-top: 40px;
    padding-bottom: 40px;
    @media(min-width: $screen-sm-min) {
      padding-top: 80px;
      padding-bottom: 66px;
    }
    .excerpt {
      p {
        font-size: 18px;
        line-height: 26px;
        @media(min-width: $screen-sm-min) {
          font-size: 32px;
          line-height: 45px;
        }
        margin: 0;
      }
    }
  }

  .content {
    .container-fluid {
      max-width: 1920px;
    }
  }
}

.container-fluid {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.container {
  padding-left: 10px;
  padding-right: 10px;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}

.page-header {
  border: 0;
}