.share-buttons {
  list-style: none;
  max-width: 680px;
  margin: 0 auto;
  padding: 0 30px;
  margin-bottom: 30px;
  @media(min-width: $screen-sm-min) {
    margin-bottom: 50px;
  }
  @media(min-width: $screen-sm-min) {
    padding: 0;
  }
  li {
    margin-right: 18px;
    float: left;
    padding: 0;
    a {
      display: block;
      transition: opacity $secondary-transition-delay;
      &:hover {
        opacity: $primary-hover-opacity;
      }
    }
  }

  .mail {
    background: url(../images/mail-icon.svg) no-repeat;
    margin-top: 2px;
    height: 17px;
    width: 21px;
  }

  .fb {
    background: url(../images/fb-icon.svg) no-repeat;
    height: 20px;
    width: 9px;
  }

  .tw {
    background: url(../images/tw-icon.svg) no-repeat;
    margin-top: 2px;
    height: 19px;
    width: 23px;
  }

  .li {
    background: url(../images/li-icon.svg) no-repeat;
    margin-top: -2px;
    height: 21px;
    width: 21px;
  }
}