/* helper classes */
.vcenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  @media(min-width: $screen-sm-min) {
    margin: 0 !important;
  }
}

.mobile-padding {
  @media(max-width: $screen-md-min) {
    padding: 0 30px;
  }
}

.theme {
  color: $yellow;
  text-transform: uppercase;
  font-family: 'zurich_condensed';
  font-size: 14px;
  @media(min-width: $screen-sm-min) {
    font-size: 18px;
  }
}

.valign-middle {
  display: inline-block;
  vertical-align: middle;
}

.size-full {
  width: 100%;
  .size-full {
    width: 100%;
  }
  img {
    width: 100%;
  }
}