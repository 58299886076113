.home {
  .main {
    padding-bottom: 20px;
  }
}

.toolbox-bar {
  font-family: 'zurich_condensed', sans-serif;
  text-transform: uppercase;
  background: #e8e7e7;
  transition: background $primary-transition-delay ease;
  font-size: 22px;
  display: block;
  height: 74px;
  &:hover {
    background: #ccc;
  }
  @media(min-width: $screen-sm-min) {
    font-size: 24px;
  }
  span {
    padding-top: 3px;
    display: block;
  }
}

.content-info {
  background: $yellow;
  color: $blue;
  padding-bottom: 20px;
  @media(min-width: $screen-sm-min) {
    padding-bottom: 50px; 
  }
  .logos {
    max-width: 1230px;
    margin-top: 80px;
    &.widget-count-3 {
      max-width: 921px;
    }
    a {
      display: block;
    }
    div {
      height: 120px;
      border: 2px solid $blue;
      border-top: 0;
      float: none;
      @media(min-width: $screen-sm-min) {
        border: 2px solid $blue;
        border-right: 1px solid #005293;
        border-left: 1px solid #005293; 
        height: 150px;
        float: left;
      }
    }
    a:first-child {
      div {
        border-top: 2px solid #005293;
        border-bottom: 2px solid #005293;
      }
    }

    @media(min-width: $screen-sm-min) {
      a:first-child {
        div {
          border-left-width: 2px;
        }
      }
      a:last-child {
        div {
          border-right-width: 2px;
        }
      }
    }
  }
  .contact {
    margin-top: 50px;
    a {
      color: $blue;
      text-decoration: underline;
    }
    .widget {
      margin-bottom: 28px;
      h3 {
        font-family: 'zurich_condensed', sans-serif;
        margin-bottom: 5px;
        margin-top: 0;
        @media(min-width: $screen-sm-min) {
          font-size: 14px;
        }
      }
      p {
        margin-bottom: 28px;
        line-height: 28px;
        @media(min-width: $screen-sm-min) {
          font-size: 18px; 
        }
      }
      span {
        font-family: 'zurich_condensed', sans-serif;
        @media(min-width: $screen-sm-min) {
          font-size: 14px;
        }
      }
    }
  }
}