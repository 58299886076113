.owl-carousel {
  .owl-item {
    -webkit-transform: translateZ(0);
  }

  .owl-nav {
    margin-top: 0;
  }
  
  .slide {
    &.small-text {
      
      .fact-value {
        font-size: 40px;
        @media(min-width: $screen-sm-min) {
          font-size: 92px;
        }
      }
    }
    &.big-text {
      .fact-value {
        font-size: 60px;
        @media(min-width: $screen-sm-min) {
          font-size: 180px;
        }
      }
    }
    &.medium-text {
      @media(min-width: $screen-sm-min) {
        font-size: 140px;
      }
    }
   
    .content-container {
      position: absolute;
      font-size: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      top: 0;
      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
      .text-wrapper {
        margin-left: 12%;
        width: 75%;
        @media(min-width: $screen-sm-min) {
          margin-left: 15%;
          width: 70%;
        }

        .fact-value {
          font-family: 'sweden_sansbold', sans-serif;
          color: $yellow;
          line-height: 1;
          margin-left: -1px;
          @media(min-width: $screen-sm-min) {
            margin-left: -6px;
          }
        }
        p {
          font-family: 'sweden_sansregular', sans-serif;
          margin-top: 0;
          margin-bottom: 0;
          line-height: 22px;
          font-size: 18px;
          color: #fff;

          @media(min-width: $screen-sm-min) {
            line-height: 26px;
            font-size: 20px;
          }

          @media(min-width: $screen-lg-min) {
            line-height: 36px;
            font-size: 28px;
          }
        }
      }
    }
  }

  &.owl-theme {
    .owl-nav {
      div {
        background: transparent url(../images/yellow-arrow.png) no-repeat;
        background-size: 100% 100% !important;
        position: absolute;
        transition: opacity $primary-transition-delay;
        width: 24px;
        height: 27px;
        z-index: 2;
        top: 42%;
        @media(min-width: $screen-sm-min) {
          width: 31px;
          top: 49%;
        }
        
        &:hover {
          background: transparent url(../images/yellow-arrow.png) no-repeat;
          opacity: $primary-hover-opacity;
        }

        &.owl-prev {
          transform: rotate(180deg);
          left: 0;
          @media(min-width: $screen-sm-min) {
            left: 35px;
          }
        }
        &.owl-next {
          right: 0;
          @media(min-width: $screen-sm-min) {
            right: 35px;
          }
        }
      }
    }
  }

  .owl-dots {
    position: absolute;
    bottom: 10px;
    z-index: 2;
    left: 12%;
    @media(min-width: $screen-sm-min) {
      bottom: 24px;
      left: 15%;
    }

    .owl-dot {
      font-size: 18px;
      cursor: default;
      color: $yellow;
      &:not(.active) {
        display: none;
      }
      @media(min-width: $screen-sm-min) {
        font-size: 24px;
      }
    }
  }
}

.reason {
  h2 {
    color: $yellow;
    margin: 0;
    line-height: 22px;
    font-size: 18px;
    @media(min-width: $screen-sm-min) {
            line-height: 26px;
            font-size: 20px;
    }

    @media(min-width: $screen-lg-min) {
      line-height: 36px;
      font-size: 28px;
    }
  }
}